import React from 'react';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation();

  // Function to change language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar">
      <h2>LORIS-HUTTER</h2>
      <ul>
        <li><a href="#header">{t('NAVIGATION.HOME')}</a></li>
        <li><a href="#about">{t('NAVIGATION.ABOUT')}</a></li>
        <li><a href="#skills">{t('NAVIGATION.SKILLS')}</a></li>
        <li><a href="#portfolio">{t('NAVIGATION.PORTFOLIO')}</a></li>
        {/* <li><a href="#contact">{t('NAVIGATION.CONTACT')}</a></li> */}
      </ul>
      <div className='select-container'>
        <select onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">🇬🇧 EN</option>
          <option value="de">🇩🇪 DE</option>
        </select>
      </div>
    </nav>
  );
}

export default Navbar;
