import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';

function Skills({ projects }) {
  const { t } = useTranslation();
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [expandedSkill, setExpandedSkill] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const skillsData = [
    { name: 'Web3', level: 90, color: '#D84924' },
    { name: 'ReactJS', level: 86, color: '#5CD0EE' },
    { name: 'Python', level: 78, color: '#f29d00' },
    { name: 'SQL', level: 75, color: '#006f88' },
    { name: 'Git', level: 72, color: '#e44c30' },
    { name: 'Linux', level: 72, color: '#e95420' },
    { name: 'Unity', level: 70, color: '#de323c' },
    { name: 'Scrum', level: 70, color: '#8b6fab' },
    { name: 'C#', level: 65, color: '#92458c' },
    { name: 'API', level: 65, color: '#1f6f92' },
    { name: 'Jira', level: 60, color: '#2d88ff' },
    { name: 'Confluence', level: 60, color: '#186de4' },
    { name: 'Arduino', level: 60, color: '#006f88' },
    { name: 'Java', level: 55, color: '#e97b18' },
    { name: 'Spring boot', level: 55, color: '#6cb52d' },
    { name: 'Cyber security', level: 50, color: '#0b72b0' },
    { name: 'Docker', level: 40, color: '#2496ed' },
    { name: 'Project lead', level: 30, color: '#de323c' },
    { name: 'C++', level: 25, color: '#1d659c' },
    { name: 'Kotlin', level: 25, color: '#92458c' },
    { name: 'Machine learning', level: 25, color: '#ff421b' },
    { name: 'Community management', level: 10, color: '#ead41c' }
  ];

  const displayedSkills = showAllSkills ? skillsData : skillsData.slice(0, 4);

  const toggleSkillExpansion = (skillName) => {
    setExpandedSkill(expandedSkill === skillName ? null : skillName);
    setCurrentProjectIndex(0); // Reset index on skill toggle
  };

  const getProjectsForSkill = (skillName) => {
    const skillProjects = projects.filter((project) => project.tags.includes(skillName));
    return skillProjects;
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const currentProjects = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    return skillProjects.slice(currentProjectIndex, currentProjectIndex + 3); // Show 3 projects at a time
  };

  const nextProject = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    setCurrentProjectIndex((currentProjectIndex + 1) % skillProjects.length);
  };

  const prevProject = (skillName) => {
    const skillProjects = getProjectsForSkill(skillName);
    setCurrentProjectIndex(
      (currentProjectIndex - 1 + skillProjects.length) % skillProjects.length
    );
  };

  return (
    <section id='skills' className={`skills-container ${inView ? 'fade-in' : ''}`} ref={ref}>
      <div className="skills-content">
        <div className="skills-left">
          <div className="chip">{t('SKILLS.CHIP')}</div>
          <h2>{t('SKILLS.TITLE')}</h2>
          <p>{t('SKILLS.DESCRIPTION')}</p>
        </div>

        <div className="skills-right">
          {displayedSkills.map((skill) => (
            <div key={skill.name} className="skill-container">
              <div className="skill-row">
                <div
                  className="skill-bar"
                  onClick={() => toggleSkillExpansion(skill.name)}
                  style={{ background: `linear-gradient(to right, ${skill.color} ${skill.level}%, #ddd ${skill.level}%)` }}
                >
                  <span className="skill-name">{skill.name}</span>
                </div>
                <span className="skill-level">{skill.level}%</span>
              </div>
              {expandedSkill === skill.name && (
                <div className="project-gallery expanded">
                  <button className="prev-project" onClick={() => prevProject(skill.name)}>❮</button>
                  {currentProjects(skill.name).map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      onClick={() => handleProjectClick(project)}
                      size="small"
                    />
                  ))}
                  <button className="next-project" onClick={() => nextProject(skill.name)}>❯</button>
                </div>
              )}
            </div>
          ))}
          <button onClick={() => setShowAllSkills(!showAllSkills)}>
            {showAllSkills ? t('SKILLS.VIEW_LESS') : t('SKILLS.VIEW_ALL')}
          </button>
        </div>
      </div>
      {selectedProject && (
        <ProjectModal project={selectedProject} onClose={() => setSelectedProject(null)} />
      )}
    </section>
  );
}

export default Skills;
