import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-nav">
        <ul>
          <li><a href="#header">{t('NAVIGATION.HOME')}</a></li>
          <li><a href="#about">{t('NAVIGATION.ABOUT')}</a></li>
          <li><a href="#skills">{t('NAVIGATION.SKILLS')}</a></li>
          <li><a href="#portfolio">{t('NAVIGATION.PORTFOLIO')}</a></li>
          {/* <li><a href="#contact">{t('NAVIGATION.CONTACT')}</a></li> */}
        </ul>
      </div>
      <div className="additional-info">
        <p>{t('FOOTER.SITESIZE')}: 69 MB</p>
        <p>{t('FOOTER.HOURSSPENT')}: 50 {t('FOOTER.HOURS')}</p>
        <p>&copy; 2018-{new Date().getFullYear()} Loris Thierry Hutter. {t('FOOTER.RIGHTS')}. Version (0.8.0.0)</p>
        <a href="/privacy-policy">{t('FOOTER.PRIVACY')}</a>
      </div>
    </footer>
  );
}

export default Footer;
