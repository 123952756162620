import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialIcon } from 'react-social-icons';

function Header() {
  const { t } = useTranslation();

  function getAge() {
    const todayDate = new Date();
    const today = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
    const year_born = [2002, 2, 15];
    const difference = [0, 0, 0];

    for (let i = 0; i < 3; i++) {
      difference[i] = today[i] - year_born[i];
    }
    if (difference[2] < 0) difference[1] -= 1;
    if (difference[1] < 0) difference[0] -= 1;

    return difference[0];
  }

  let age = getAge();

  return (
    <header id="header" className="header">
      <div className="header-section left">
        <h2>Loris Hutter</h2>
        <h3>{t('HEADER.IAM')} {age} {t('HEADER.YEARSOLD')}</h3>
        <h3 className="occupation">{t('HEADER.OCCUPATION')}</h3>
      </div>
      <div className="header-section middle">
        <img src={'newme.jpg'} alt={t('HEADER_NAME')} className="profile-photo" />
        <div className="social-icons">
          {/* <SocialIcon url="https://www.patreon.com/raccoongamestudios/creators" bgColor="#FA6A56" fgColor="#ffffff" /> */}
          <SocialIcon url="https://raccoongamestudios.itch.io/" fgColor="#ffffff" />
          <SocialIcon url="https://www.instagram.com/loris.letelier/" bgColor="#F03D5B" fgColor="#6266E1" />
          <SocialIcon url="https://github.com/LorisThierryHutter" fgColor="#ffffff" bgColor="#15181C" />
          <SocialIcon url="https://www.linkedin.com/in/loris-thierry-h%C3%BCtter-8276b5156/" fgColor="#ffffff" />
          <SocialIcon url="https://stackoverflow.com/users/14103392/loristhierryhuetter" bgColor="#E87A22" fgColor="#ffffff" />
          <SocialIcon url="https://www.sololearn.com/profile/830484" fgColor="#0A3267" bgColor="#E36F40" />
        </div>
      </div>
      <div className="header-section right">
        <p>{t('HEADER.INTRODUCTION')}</p>
        <p>{t('HEADER.DESCRIPTION')}</p>
      </div>
    </header>
  );
}

export default Header;
