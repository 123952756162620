import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

function ProjectModal({ project, onClose }) {
  const { t } = useTranslation();

  // console.log('Project received in ProjectModal:', project);

  const images = project?.images ? project.images.split(',') : [];
  const tags = Array.isArray(project?.tags) ? project.tags : project?.tags ? project.tags.split(',') : [];  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // console.log('Images:', images);
  // console.log('Tags:', tags);

  // Prevent background scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!project) {
    // console.log('Project data is not ready:', project);
    return null; // Render nothing if project is invalid
  }  

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const modalContent = (
    <div className="project-modal-overlay" onClick={onClose}>
      <div className="project-modal" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>×</span>
        <div className="image-carousel">
          <img src={images[currentImageIndex]} alt={project.title} />
          {images.length > 1 && (
            <>
              <button className="prev" onClick={prevImage}>❮</button>
              <button className="next" onClick={nextImage}>❯</button>
            </>
          )}
        </div>
        <div className="project-tags">
          {tags.map((tag, index) => (
            <span key={index} className="tag">#{tag.trim()}</span>
          ))}
        </div>
        <h2>{project.title}</h2>
        <p>{project.full_description}</p>
        <div className="project-links">
          {project.project_link && (
            <a href={project.project_link} target="_blank" rel="noopener noreferrer">
              {t('PORTFOLIO.VIEW_PROJECT')}
            </a>
          )}
          {project.github_link && (
            <a href={project.github_link} target="_blank" rel="noopener noreferrer">
              {t('PORTFOLIO.VIEW_GITHUB')}
            </a>
          )}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.getElementById('modal-root'));
}

export default ProjectModal;
